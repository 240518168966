import ProductSearchActionTypes from './ProductSearchActionTypes';
import ProductSearchReducerInitialState from './ProductSearchReducerInitialState';

export const ProductSearchReducer = (state = ProductSearchReducerInitialState, action = '') => {
  switch (action.type) {
    case ProductSearchActionTypes.SET_CACHE:
      return {
        ...state,
        cached: action.payload,
      };
    case ProductSearchActionTypes.SET_VIEW:
      return {
        ...state,
        view: action.payload,
      };
    case ProductSearchActionTypes.SET_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };
    case ProductSearchActionTypes.SET_DATE_FROM:
      return {
        ...state,
        date_from: action.payload,
      };
    case ProductSearchActionTypes.SET_DATE_TO:
      return {
        ...state,
        date_to: action.payload,
      };
    case ProductSearchActionTypes.SET_SEARCH_DATES:
      return {
        ...state,
        date_from: action.date_from,
        date_to: action.date_to,
      };
    case ProductSearchActionTypes.SET_GEO:
      return {
        ...state,
        geo: action.payload,
      };
    case ProductSearchActionTypes.SET_COMPONENT_IDS:
      return {
        ...state,
        component_ids: action.payload,
      };
    case ProductSearchActionTypes.RESET_FILTERS:
      return ProductSearchReducerInitialState;
    default:
      return state;
  }
};

export default ProductSearchReducer;
