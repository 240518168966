import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import commonTranslationsDE from './de/common.json';
import commonTranslationsEN from './en/common.json';
import mywTranslationsDE from './de/myw.json';
import mywTranslationsEN from './en/myw.json';
import { getAppConfig } from '../utils/appConfig';

const resources = {
  en: {
    common: commonTranslationsEN,
    myw: mywTranslationsEN,
  },
  de: {
    common: commonTranslationsDE,
    myw: mywTranslationsDE,
  },
};

const { language_prefix: lang } = getAppConfig();

i18n
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false },
    keySeparator: '.',
    resources,
    lng: lang || 'en',
    fallbackLng: 'en',
  });

// Load German translations for calendar.
(async () => {
  if (lang === 'de') {
    await import('moment/locale/de');
  }
})();

export default i18n;
