import AppConfigActionTypes from './AppConfigActionTypes';
import AppConfigReducerInitialState from './AppConfigReducerInitialState';

export const AppConfigReducer = (state = AppConfigReducerInitialState, action = '') => {
  switch (action.type) {
    case AppConfigActionTypes.SET_PRODUCT_ID:
      return {
        ...state,
        product_id: action.payload,
        product_loaded: false,
      };

    case AppConfigActionTypes.SET_PRODUCT_LOADED:
      return {
        ...state,
        product_loaded: action.payload,
      };

    case AppConfigActionTypes.SET_API_BASE_URL:
      return {
        ...state,
        api_base_url: action.payload,
      };

    case AppConfigActionTypes.SET_API_CART_URL:
      return {
        ...state,
        api_cart_url: action.payload,
      };

    case AppConfigActionTypes.SET_API_SESSION_URL:
      return {
        ...state,
        api_session_url: action.payload,
      };

    case AppConfigActionTypes.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        current_language: action.payload,
      };

    case AppConfigActionTypes.SET_INCLUDES_GEO:
      return {
        ...state,
        include_geo: parseInt(action.payload, 10),
      };

    case AppConfigActionTypes.SET_SESSION_REQUIRED:
      return {
        ...state,
        session_required: parseInt(action.payload, 10),
      };

    case AppConfigActionTypes.SET_BRAND:
      return {
        ...state,
        brand: action.payload,
      };

    default:
      return state;
  }
};

export default AppConfigReducer;
