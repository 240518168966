import React, { useReducer, useMemo } from 'react';
import { node } from 'prop-types';
import { AppConfigContext } from '../../contexts/AppConfigContext';
import { AppConfigReducer } from '../../reducers/AppConfigReducer';
import { getAppConfig } from '../../utils/appConfig';

export const AppConfigProvider = ({ children }) => {
  // Get the site config from one of the expected locations
  // and configure the product search. For Drupal websites, this
  // will be the Drupal settings.
  const initialAppConfig = getAppConfig();

  // Store the current app config in state.
  const [appConfig, setAppConfig] = useReducer(
    AppConfigReducer,
    initialAppConfig,
  );

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const AppConfigContextValue = useMemo(() => ({
    appConfig,
    setAppConfig,
  }), [appConfig]);

  return (
    <AppConfigContext.Provider value={AppConfigContextValue}>{children}</AppConfigContext.Provider>
  );
};

AppConfigProvider.propTypes = {
  children: node.isRequired,
};

AppConfigProvider.defaultProps = {};

export default AppConfigProvider;
