const ProductSearchActionTypes = {
  SET_CACHE: 'SET_CACHE',
  SET_VIEW: 'SET_VIEW',
  SET_FIELDS: 'SET_FIELDS',
  SET_DATE_FROM: 'SET_DATE_FROM',
  SET_DATE_TO: 'SET_DATE_TO',
  SET_SEARCH_DATES: 'SET_SEARCH_DATES',
  SET_GEO: 'SET_GEO',
  SET_COMPONENT_IDS: 'SET_COMPONENT_IDS',
  RESET_FILTERS: 'RESET_FILTERS',
};

export default ProductSearchActionTypes;
