import React, { useState, useMemo } from 'react';
import { node } from 'prop-types';
import { CartContext } from '../../contexts/CartContext';

export const CartProvider = ({ children }) => {
  // Store the current lang in state.
  const [cart, setCart] = useState({
    group: null,
    flex_price_option_id: 'standard',
    qty: [],
    extras: [],
    numOfDays: 1,
    productId: '',
  });

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const CartContextValue = useMemo(() => ({
    cart,
    setCart,
  }), [cart]);

  return (
    <CartContext.Provider value={CartContextValue}>{children}</CartContext.Provider>
  );
};

CartProvider.propTypes = {
  children: node.isRequired,
};

CartProvider.defaultProps = {};

export default CartProvider;
