import React, { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { AppConfigProvider } from './providers/AppConfigProvider';
import { CartProvider } from './providers/CartProvider';
import { ProductSearchProvider } from './providers/ProductSearchProvider';
import { ABContextProvider } from './providers/ABContextProvider';
import { Spinner } from './components/Spinner';
import './App.scss';

// Lazy load the app whilst the display filters are being set up.
const LazyLayout = React.lazy(() => import('./components/Layout').then(({ Layout }) => ({ default: Layout })));

// Initialise React Query Client.
const queryClient = new QueryClient();

const App = () => (
  <ABContextProvider>
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Spinner />}>
        <AppConfigProvider>
          <ProductSearchProvider>
            <CartProvider>
              <LazyLayout />
            </CartProvider>
          </ProductSearchProvider>
        </AppConfigProvider>
      </Suspense>
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  </ABContextProvider>
);

export default App;
