import React, {
  useMemo,
  useState,
  useEffect,
  useReducer,
} from 'react';
// Default currency $.
import numeral from 'numeral';
// Add £.
import 'numeral/locales/en-gb';
// Add €.
import '../../locales/numeral/en-ie';
// Add $AU.
import '../../locales/numeral/en-au';
import { node } from 'prop-types';
import { useAppConfigContext } from '../../hooks/useAppConfigContext';
import { ProductSearchContext } from '../../contexts/ProductSearchContext';
import { ProductSearchReducer } from '../../reducers/ProductSearchReducer';
import ProductSearchActionTypes from '../../reducers/ProductSearchReducer/ProductSearchActionTypes';
import ProductSearchReducerInitialState from '../../reducers/ProductSearchReducer/ProductSearchReducerInitialState';

export const ProductSearchProvider = ({ children }) => {
  // Get the app config state.
  const {
    appConfig,
  } = useAppConfigContext();

  // Store the date that has been selected.
  const [selectedDate, setSelectedDate] = useState();

  // Store in state whether the current product is calendar based.
  const [isCalendar, setIsCalendar] = useState();

  // Store the current product search parameters in state.
  const [productSearch, setProductSearch] = useReducer(
    ProductSearchReducer,
    ProductSearchReducerInitialState,
  );

  // When the lang changes (initial selection), we need to update the related states.
  useEffect(() => {
    // Update the currency formatting.
    let numeralLocale = '';
    if (appConfig.current_language === 'en') {
      numeralLocale = 'en-gb';
    } else if (appConfig.current_language === 'de-de' || appConfig.current_language === 'en-ie') {
      numeralLocale = 'en-ie';
    } else if (appConfig.current_language === 'au' || appConfig.current_language === 'en-au') {
      numeralLocale = 'en-au';
    }
    if (numeralLocale.length > 0) {
      numeral.locale(numeralLocale);
    } else {
      numeral.reset();
    }

    // Update the search if we are using the geo parameter.
    if (appConfig.include_geo) {
      setProductSearch({
        type: ProductSearchActionTypes.SET_GEO,
        payload: appConfig.current_language,
      });
    }
  }, [appConfig.current_language]);

  // When product id changes, we need to reset whether the product
  // is calender based.
  useEffect(() => {
    setIsCalendar(null);
  }, [appConfig.product_id]);

  // Check if the geo should be unset.
  useEffect(() => {
    if (!appConfig.include_geo) {
      setProductSearch({
        type: ProductSearchActionTypes.SET_GEO,
        payload: '',
      });
    }
  }, [appConfig.include_geo]);

  // You should use useMemo to memoize the values returned to the Context Provider.
  // It reduces context consumers from re-rendering if no changes occur.
  const ProductSearchContextValue = useMemo(() => ({
    productSearch,
    setProductSearch,
    selectedDate,
    setSelectedDate,
    isCalendar,
    setIsCalendar,
  }), [productSearch, selectedDate, isCalendar]);

  return (
    <ProductSearchContext.Provider value={ProductSearchContextValue}>{children}</ProductSearchContext.Provider>
  );
};

ProductSearchProvider.propTypes = {
  children: node.isRequired,
};

ProductSearchProvider.defaultProps = {};

export default ProductSearchProvider;
