const ProductSearchReducerInitialState = {
  cached: (process.env.REACT_APP_API_CACHED_REQUESTS) ? process.env.REACT_APP_API_CACHED_REQUESTS : 1,
  view: 'website',
  fields: [
    'title',
    'seo_title',
    'attractions',
    'calendar_based_pricing',
    'calendar_based_multiple',
    'sale_cur',
    'price_options',
    'fulfilment_options',
    'ticket_includes',
    'ticket_excludes',
    'additional_info',
    'tickets/ticket_id',
    'tickets/product_id',
    'tickets/title',
    'tickets/type',
    'tickets/type_description',
    'tickets/price_from',
    'tickets/year',
    'tickets/grouping',
    'tickets/ticket_num_days',
    'tickets/availability',
    'tickets/internal/is_hidden',
    'tickets/ticket_components',
    'seasons/date_from',
    'seasons/date_to',
    'seasons/tickets',
    'seasons/class',
  ],
  date_from: 'auto',
  date_to: 'auto',
  geo: 'en',
};

export default ProductSearchReducerInitialState;
