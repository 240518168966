const AppConfigReducerInitialState = {
  product_id: (process.env.REACT_APP_PRODUCT_ID) ? process.env.REACT_APP_PRODUCT_ID : '',
  product_loaded: false,
  extras: process.env.REACT_APP_EXTRAS ? process.env.REACT_APP_EXTRAS : [],
  api_base_url: (process.env.REACT_APP_API_BASE_URL) ? process.env.REACT_APP_API_BASE_URL : `//${window.location.hostname}/api`,
  api_cart_url: (process.env.REACT_APP_API_CART_URL) ? process.env.REACT_APP_API_CART_URL : `//${window.location.hostname}/api/cart`,
  api_session_url: (process.env.REACT_APP_API_SESSION_URL) ? process.env.REACT_APP_API_SESSION_URL : `//${window.location.hostname}/api/session`,
  current_language: (process.env.REACT_APP_DEFAULT_LANG) ? process.env.REACT_APP_DEFAULT_LANG : 'en',
  include_geo: (typeof process.env.REACT_APP_INCLUDE_GEO !== 'undefined') ? parseInt(process.env.REACT_APP_INCLUDE_GEO, 10) : 1,
  session_required: (typeof process.env.REACT_APP_SESSION_REQUIRED !== 'undefined') ? parseInt(process.env.REACT_APP_SESSION_REQUIRED, 10) : 0,
  brand: (process.env.REACT_APP_BRAND) ? process.env.REACT_APP_BRAND : '',
  is_myw: (process.env.REACT_APP_IS_MYW) ? parseInt(process.env.REACT_APP_IS_MYW, 10) : 0,
};

export default AppConfigReducerInitialState;
