function isABTest(testName) {
  window.dataLayer = window.dataLayer || [];
  // get all cookies
  const cookie = document.cookie;
  // check if cookie for test exists and fire data layer events and return true or false
  if (cookie.includes(`${testName}=1`)) {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'abtest',
      test: {
        name: testName,
        group: 'variant',
      },
    });
    document.body.classList.add(testName);
    return true;
  }
  if (cookie.includes(`${testName}=0`)) {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'abtest',
      test: {
        name: testName,
        group: 'control',
      },
    });
    return false;
  }
  // if no cookie exists for 'testName' then generate group (control or variant) and create cookie
  const group = Math.random() < 0.5 ? 1 : 0;
  document.cookie = `test-${testName}=${group};path=/;max-age=31536000`;

  if (group === 1) {
    // eslint-disable-next-line no-undef
    dataLayer.push({
      event: 'abtest',
      test: {
        name: testName,
        group: 'variant',
      },
    });
    document.body.classList.add(testName);
    return true;
  }

  // eslint-disable-next-line no-undef
  dataLayer.push({
    event: 'abtest',
    test: {
      name: testName,
      group: 'control',
    },
  });
  return false;
}

export default isABTest;
