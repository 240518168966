import React, {
  useEffect,
  useState,
} from 'react';
import { node } from 'prop-types';
import { ABContext } from '../../contexts/ABContext';
import isABTest from '../../ab/isabtest';
import { getAppConfig } from '../../utils/appConfig';

export const ABContextProvider = ({ children }) => {
  const [abTests, setABTests] = useState({});

  // Get the app config state.
  const appConfig = getAppConfig();

  // CRO-1234-test-name
  useEffect(() => {
    // comment/uncomment this line below to make test pause/work:
    // const cro1234IsVariant = isABTest('CRO-1234-test-name');

    // Add context
    const testcro1234 = ({
      cro1234: {
        name: 'CRO-1234-test-name',
        // comment/uncomment this line below to make test pause/work:
        // variant: cro1234IsVariant,
        // uncomment this line below if we want to pause test:
        variant: 0,
      },
    });

    // Update state
    setABTests((prevState) => ({
      ...prevState,
      ...testcro1234,
    }));
  }, []);

  return (
    <ABContext.Provider value={abTests}>{ children }</ABContext.Provider>
  );
};

ABContextProvider.propTypes = {
  children: node.isRequired,
};

ABContextProvider.defaultProps = {};

export default ABContextProvider;
