import AppConfigReducerInitialState from '../reducers/AppConfigReducer/AppConfigReducerInitialState';

/** Get the app config. */
export const getAppConfig = () => {
  // Get the site config from one of the expected locations
  // and configure the product search. For Drupal websites, this
  // will be the Drupal settings.
  let appConfig = AppConfigReducerInitialState;
  if (
    typeof drupalSettings !== 'undefined'
    // Drupal 8 / 9 - drupalSettings.
    && drupalSettings.dvp_app !== 'undefined'
  ) {
    appConfig = drupalSettings.dvp_app;
  } else if (
    typeof Drupal !== 'undefined'
    && typeof Drupal.settings !== 'undefined'
    && Drupal.settings.dvp_app !== 'undefined'
  ) {
    // Drupal 6 / 7 - Drupal.settings.
    appConfig = Drupal.settings.dvp_app;
  }

  return appConfig;
};

/** Get the API Base Url from the the app config. */
export const getApiBaseUrl = () => {
  let apiBaseUrl = `//${window.location.hostname}/api`;
  const appConfig = getAppConfig();
  if (
    typeof appConfig !== 'undefined'
    && appConfig.api_base_url !== 'undefined'
  ) {
    apiBaseUrl = appConfig.api_base_url;
  }

  return apiBaseUrl;
};
