const AppConfigActionTypes = {
  SET_PRODUCT_ID: 'SET_PRODUCT_ID',
  SET_PRODUCT_LOADED: 'SET_PRODUCT_LOADED',
  SET_API_BASE_URL: 'SET_API_BASE_URL',
  SET_API_CART_URL: 'SET_API_CART_URL',
  SET_API_SESSION_URL: 'SET_API_SESSION_URL',
  SET_CURRENT_LANGUAGE: 'SET_CURRENT_LANGUAGE',
  SET_INCLUDES_GEO: 'SET_INCLUDES_GEO',
  SET_SESSION_REQUIRED: 'SET_SESSION_REQUIRED',
  SET_BRAND: 'SET_BRAND',
};

export default AppConfigActionTypes;
